// Generated by Framer (b0f2619)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useActiveVariantCallback, useLocaleInfo, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["ludeS1w3T", "QC2Y0TV_f"];

const serializationHash = "framer-PSqPd"

const variantClassNames = {ludeS1w3T: "framer-v-1hn660h", QC2Y0TV_f: "framer-v-rbkp47"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 72, delay: 0.1, mass: 1, stiffness: 79, type: "spring"}};

const transformTemplate1 = (_, t) => `translate(-50%, -50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Variant 1": "ludeS1w3T", "Variant 2": "QC2Y0TV_f"}

const getProps = ({height, id, title, width, ...props}) => { return {...props, pWFO9V4o3: title ?? props.pWFO9V4o3 ?? "menu", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "ludeS1w3T"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, pWFO9V4o3, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "ludeS1w3T", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppear1untkpy = activeVariantCallback(async (...args) => {
await delay(() => setVariant("QC2Y0TV_f"), 900)
})

const onAppear2acx57 = activeVariantCallback(async (...args) => {
await delay(() => setVariant("ludeS1w3T"), 3100)
})

useOnVariantChange(baseVariant, {default: onAppear1untkpy, QC2Y0TV_f: onAppear2acx57})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><motion.div {...restProps} animate={variants} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1hn660h", className, classNames)} data-framer-name={"Variant 1"} data-highlight initial={variant} layoutDependency={layoutDependency} layoutId={"ludeS1w3T"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{opacity: 1, ...style}} variants={{QC2Y0TV_f: {opacity: 0}}} {...addPropertyOverrides({QC2Y0TV_f: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "Q1VTVE9NO0dlaXN0IE1lZGl1bQ==", "--framer-font-family": "\"Geist Medium\", \"Geist Medium Placeholder\", sans-serif", "--framer-font-size": "15px", "--framer-line-height": "1em", "--framer-text-color": "var(--extracted-r6o4lv, rgb(255, 255, 255))"}}>menu</motion.p></React.Fragment>} className={"framer-xm9tu3"} fonts={["CUSTOM;Geist Medium"]} layoutDependency={layoutDependency} layoutId={"KV4GQDpnR"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", textShadow: "1px 1px 1px rgba(0, 0, 0, 0.25)"}} text={pWFO9V4o3} transformTemplate={transformTemplate1} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition>
</LayoutGroup>)

});

const css = [".framer-PSqPd[data-border=\"true\"]::after, .framer-PSqPd [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-PSqPd.framer-o81ar7, .framer-PSqPd .framer-o81ar7 { display: block; }", ".framer-PSqPd.framer-1hn660h { height: 16px; overflow: hidden; position: relative; width: 42px; }", ".framer-PSqPd .framer-xm9tu3 { flex: none; height: auto; left: 50%; position: absolute; top: 50%; white-space: pre; width: auto; z-index: 1; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 16
 * @framerIntrinsicWidth 42
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"QC2Y0TV_f":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"pWFO9V4o3":"title"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const Framerq1_UUNLlz: React.ComponentType<Props> = withCSS(Component, css, "framer-PSqPd") as typeof Component;
export default Framerq1_UUNLlz;

Framerq1_UUNLlz.displayName = "menu";

Framerq1_UUNLlz.defaultProps = {height: 16, width: 42};

addPropertyControls(Framerq1_UUNLlz, {variant: {options: ["ludeS1w3T", "QC2Y0TV_f"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}, pWFO9V4o3: {defaultValue: "menu", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(Framerq1_UUNLlz, [{family: "Geist Medium", url: "https://framerusercontent.com/assets/dNqH0c4qjo3RGmYZnJknwKtT0.woff2"}])